class DataLoader
{
    constructor(code, name) 
    {
        this.code = code;
        this.name = name;
        this.url = `/img/${code}.ico`;
        this.selected = false;
        this.type = "url";

        if (name == null)
        {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            this.name = "";
            for (let i = 0; i < 8; i++)
            {
                this.name += characters[Math.floor(Math.random() * characters.length)];
            }
            this.code = this.name;
            this.name += ".exe";
            this.type = "file";
        }
    }
}

export { DataLoader };

const defaultLoaders = [
    new DataLoader("chrome", "Google chrome.exe"), new DataLoader("firefox", "Firefox.exe"), new DataLoader("edge", "Edge.exe"), new DataLoader("opera", "Opera Browser.exe")
];

defaultLoaders[0].selected = true;

export default defaultLoaders;