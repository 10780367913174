import React, {useState, useRef, useEffect} from "react";

import defaultLoaders, {DataLoader} from "./data";

import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./Loader.module.css";

// import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {createTheme, ThemeProvider, CssBaseline} from "@mui/material";
import {Button, TextField, Slider} from "@mui/material";

const darkTheme = createTheme({
    palette: {
        mode: "dark", // Устанавливаем темный режим
    },
});

function LoaderIcon(props) {
    const onClick = (loader, setName) => {
        loader.selected = true;
        setName(loader);
    };

    return (
        <Col
            style={{marginRight: "35px", marginTop: "5px"}}
            xs="2"
            sm="2"
            md="1">
            <div
                className={
                    styles.loaderIcon +
                    (props.loader.selected ? " " + styles.active : "")
                }
                onClick={() =>
                    onClick(props.loader, props.setLoaderNameFunction)
                }>
                <img src={props.loader.url} />
            </div>
        </Col>
    );
}

function AddLoaderIcon(props) {
    let inputRef = useRef(null);

    const onClick = () => {
        inputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            let newLoader = new DataLoader("custom", null);
            newLoader.url = imageUrl;
            newLoader.selected = true;
            newLoader.file = file;
            props.updateLoaders(newLoader);
            props.setLoaderName(newLoader);
        }
    };

    return (
        <Col
            style={{marginRight: "35px", marginTop: "5px"}}
            xs="2"
            sm="2"
            md="1">
            <div className={styles.loaderIcon} onClick={onClick}>
                <img
                    src="/img/plus.png"
                    style={{width: "16px", height: "16px"}}
                />
            </div>
            <input
                type="file"
                accept=".ico"
                ref={inputRef}
                onChange={handleFileChange}
                style={{display: "none"}}
                id="file-input"
            />
        </Col>
    );
}

export default function Loader() {
    let [loaderSize, setLoaderSize] = useState(
        Math.floor(Math.random() * 20000)
    );

    let [loaderName, setLoaderName_] = useState(defaultLoaders[0].name);

    const setLoaderName = (value) => {
        setLoaders((prevLoaders) => {
            const v = [...prevLoaders];
            for (let i = 0; i < v.length; i++) {
                if (v[i].code != value.code) v[i].selected = false;
            }

            return v;
        });
        setLoaderName_(value.name);
    };

    const [loaders, setLoaders] = useState(defaultLoaders);

    const updateLoaders = (newLoader) => {
        setLoaders((prevLoaders) => [...prevLoaders, newLoader]);
    };

    const downloadLoader = async () => {
        let selected = null;
        for (let i = 0; i < loaders.length; i++)
            if (loaders[i].selected) selected = loaders[i];

        if (selected == null) {
            alert("Error #0");
            return;
        }

        let iconData = selected.code;
        if (selected.type === "file") {
            iconData = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const base64File = e.target.result.split(",")[1];
                    resolve(base64File);
                };
                reader.onerror = function (error) {
                    reject(error);
                };
                reader.readAsDataURL(selected.file);
            });
        }

        const data = {
            type: selected.type,
            data: iconData,
            size: loaderSize,
        };

        const response = await fetch("/api/loader/init", {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            const json = await response.json();
            const url = json.url + "&name=" + encodeURIComponent(loaderName);
            window.open(url);
        } else {
            alert("Error while downloading. Error: " + (await response.text()));
        }
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Container>
                <Row className="mt-5">
                    <Col md="3" xs="0"></Col>
                    <Col md="6" xs="12" className={styles.loaderBlock}>
                        <h3>Загрузчик</h3>

                        <div className={styles.element}>
                            <TextField
                                id="standard-helperText"
                                label="Имя файла"
                                value={loaderName}
                                helperText="С таким именем лоадер скачается на ваш ПК"
                                variant="standard"
                                onChange={(event) =>
                                    setLoaderName_(event.target.value)
                                }
                            />
                        </div>

                        <div className={styles.element}>
                            <span className={styles.elementDesc}>
                                Сколько прибавить к весу файла?
                            </span>
                            <Slider
                                aria-label="Size"
                                value={loaderSize}
                                valueLabelDisplay="auto"
                                step={1}
                                min={0}
                                max={20000}
                                onChange={(event, newValue) =>
                                    setLoaderSize(newValue)
                                }
                            />
                        </div>

                        <div className={styles.element}>
                            <span className={styles.elementDesc}>
                                Картинка лоадера
                            </span>
                            <Row className="mt-1">
                                {loaders.map((loader) => (
                                    <LoaderIcon
                                        loader={loader}
                                        setLoaderNameFunction={
                                            setLoaderName
                                        }></LoaderIcon>
                                ))}
                                <AddLoaderIcon
                                    updateLoaders={updateLoaders}
                                    setLoaderName={
                                        setLoaderName
                                    }></AddLoaderIcon>
                            </Row>
                        </div>

                        <Button
                            className="mt-3"
                            style={{width: "100%"}}
                            variant="contained"
                            onClick={downloadLoader}>
                            Скачать
                        </Button>
                    </Col>
                    <Col md="3" xs="0"></Col>
                </Row>
            </Container>
        </ThemeProvider>
    );
}
