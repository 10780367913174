// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color: #0f1214;
}

.Loader_loaderBlock__r-rBe {
    border: 1px solid rgba(61, 71, 81, 0.3);
    border-radius: 12px;
    background: #121518;
    padding: 10px 15px;
}

.Loader_loaderBlock__r-rBe h3 {
    color: #fff;
}

.Loader_element__3ame3 {
    margin-bottom: 15px;
    margin-top: 15px;
}

.Loader_elementDesc__Jn\\+PO {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1rem;
}

.Loader_loaderIcon__1aV\\+U {
    width: 64px;
    height: 64px;
    border-radius: 6px;
    background-color: #0f1214;
    border: 1px solid rgba(61, 71, 81, 0.3);
    justify-content: center;
    align-items: center;
    display: flex;
}

.Loader_loaderIcon__1aV\\+U img {
    width: 56px;
    height: 56px;
}

.Loader_loaderIcon__1aV\\+U.Loader_active__lq-Ml {
    border: 1px solid #90caf9;
}
`, "",{"version":3,"sources":["webpack://./src/pages/loader/Loader.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,uCAAuC;IACvC,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,+BAA+B;IAC/B,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,uCAAuC;IACvC,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["body {\r\n    background-color: #0f1214;\r\n}\r\n\r\n.loaderBlock {\r\n    border: 1px solid rgba(61, 71, 81, 0.3);\r\n    border-radius: 12px;\r\n    background: #121518;\r\n    padding: 10px 15px;\r\n}\r\n\r\n.loaderBlock h3 {\r\n    color: #fff;\r\n}\r\n\r\n.element {\r\n    margin-bottom: 15px;\r\n    margin-top: 15px;\r\n}\r\n\r\n.elementDesc {\r\n    color: rgba(255, 255, 255, 0.7);\r\n    font-size: 1rem;\r\n}\r\n\r\n.loaderIcon {\r\n    width: 64px;\r\n    height: 64px;\r\n    border-radius: 6px;\r\n    background-color: #0f1214;\r\n    border: 1px solid rgba(61, 71, 81, 0.3);\r\n    justify-content: center;\r\n    align-items: center;\r\n    display: flex;\r\n}\r\n\r\n.loaderIcon img {\r\n    width: 56px;\r\n    height: 56px;\r\n}\r\n\r\n.loaderIcon.active {\r\n    border: 1px solid #90caf9;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderBlock": `Loader_loaderBlock__r-rBe`,
	"element": `Loader_element__3ame3`,
	"elementDesc": `Loader_elementDesc__Jn+PO`,
	"loaderIcon": `Loader_loaderIcon__1aV+U`,
	"active": `Loader_active__lq-Ml`
};
export default ___CSS_LOADER_EXPORT___;
