// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: sans-serif;
}

header {
    border-bottom: 1px solid black;
    display: flex;
    padding: 10px 20px;
    /* justify-content: space-between; */
    align-items: center;
}

header * {
    margin-left: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,8BAA8B;IAC9B,aAAa;IACb,kBAAkB;IAClB,oCAAoC;IACpC,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["body {\n    font-family: sans-serif;\n}\n\nheader {\n    border-bottom: 1px solid black;\n    display: flex;\n    padding: 10px 20px;\n    /* justify-content: space-between; */\n    align-items: center;\n}\n\nheader * {\n    margin-left: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
